import React from 'react';
import SignInPage from './SignInPage';
const initial =()=>{
    return(
        <>
        <SignInPage/>
        </>

    )
}

export default initial