
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Initial from'./ToustUi/initial'
function App() {
  return (
    <div className="App">
      <Initial/>
    </div>
  )
}

export default App;
